module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="footer-contact">\r\n  <div class="container box-sizing">\r\n    <div class="value">请随时与我们联系以获取更多信息和EVK</div>\r\n    <div class="btn">\r\n      <a href="">联系我们</a>\r\n    </div>\r\n  </div>\r\n</section>\r\n<footer class="footer">\r\n  <div class="container box-sizing">\r\n    <div class="footer-logo">\r\n      <div class="logo">\r\n        <img src="' +
((__t = (require('./images/footer-logo.png'))) == null ? '' : __t) +
'" alt="">\r\n      </div>\r\n      <div class="footer-support">\r\n        <a href="javascript:;" data-open="map">网站导航</a>\r\n      </div>\r\n    </div>\r\n    <section class="footer-nav">\r\n      <div class="left-nav">\r\n        <ul>\r\n          <li><a href="">主页</a></li>\r\n          <li><a href="">新闻与大事</a></li>\r\n          <li><a href="">公司</a></li>\r\n          <li><a href="">整体</a></li>\r\n          <li><a href="">支持</a></li>\r\n          <li><a href="">联系方式</a></li>\r\n          <li><a href="">法律</a></li>\r\n        </ul>\r\n        <ul>\r\n          <li><span><a href="">应用</a></span></li>\r\n          <li><a href="">农业检验</a></li>\r\n          <li><a href="">人脸认证</a></li>\r\n          <li><a href="">手机/平板电脑</a></li>\r\n          <li><a href="">工业自动化</a></li>\r\n          <li><a href="">医疗健康</a></li>\r\n          <li><a href="">智能驾驶</a></li>\r\n        </ul>\r\n        <ul>\r\n          <li><span>产品</span></li>\r\n          <li><a href="">Monarch™近红外卫星</a></li>\r\n          <li><a href="">ColorIR™ NIR 可自带帘</a></li>\r\n          <li><a href="">帝王EVK</a></li>\r\n          <li><a href="">彩色红外评估板</a></li>\r\n          <li><span>技术</span></li>\r\n          <li><a href="">概述</a></li>\r\n        </ul>\r\n      </div>\r\n      <div class="right-nav">\r\n        <div class="title">关注我们并保持更新</div>\r\n        <ul>\r\n          <li>\r\n            <a href="" target="_blank">\r\n              <div class="iconfont icon-link"></div>\r\n            </a>\r\n          </li>\r\n          <li>\r\n            <a href="" target="_blank">\r\n              <div class="iconfont icon-youtube"></div>\r\n            </a>\r\n          </li>\r\n        </ul>\r\n      </div>\r\n    </section>\r\n    <section class="copyright">© 2021 保留所有权利。</section>\r\n  </div>\r\n</footer>\r\n';

}
return __p
}