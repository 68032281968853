import './index.scss'
import Swiper from 'swiper/js/swiper.min.js'

// 打开搜索
$('.nav ul li .search-icon').on('click', function() {
  if ($(this).hasClass('search-show')) {
    $(this).removeClass('search-show')
    $('.header-search').removeClass('show')
  } else {
    $(this).addClass('search-show')
    $('.header-search').addClass('show')
  }
})
// 点击打开移动端导航栏
$('.mob-menu-icon').on('click', function() {
  $('.mob-nav').addClass('show')
  $('.mob-nav-mask').fadeIn(200)
})
$('.mob-nav .close-mob-nav').on('click', function() {
  $('.mob-nav').removeClass('show')
  $('.mob-nav-mask').fadeOut(200)
})
// 滚动监听
$(document).scroll(function() {
  var scroH = $(document).scrollTop()
  if (scroH > 100) {
    $('.header').addClass('scroll-header')
  } else {
    $('.header').removeClass('scroll-header')
  }
});
// 手机端点击展开子级
$('.mob-nav .menu ul li>a').on('click',function () {
    let El = $(this).parents('li')
    $(El).hasClass('active')
    if ($(El).hasClass('active')) {
        $(El).removeClass('active').find('.subnav-mob').slideUp();
    }else{
        $(El).addClass('active').siblings().removeClass('active')
        $(El).find('.subnav-mob').slideDown();
        $(El).siblings().find('.subnav-mob').slideUp();
    }
})


// 产品页swiper
if($('.pro_info .pro-swiper').length > 0){
  var swiper = new Swiper(".pro_info .pro-swiper .mySwiper", {
    spaceBetween: 10,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesProgress: true,
  });
  var swiper2 = new Swiper(".pro_info .pro-swiper .mySwiper2", {
    speed: 800,
    loop: true,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
    spaceBetween: 10,
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev",
    // },
    thumbs: {
      swiper: swiper,
    },
  });
}